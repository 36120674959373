import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { gqlClient } from '@/lib';
import type { ExperienceTheme } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { createThemesQueryKey } from '@/utils/queryKeys';
import { ThemeFragment } from '../fragments/Theme';
import type { GetExperienceThemesQuery } from './useThemes.generated';

const query = gql`
  ${ThemeFragment}
  query GetExperienceThemes($input: GetExperienceThemesInput!) {
    getExperienceThemes(input: $input) {
      ...Theme
    }
  }
`;

const selector = (data: GetExperienceThemesQuery): ExperienceTheme[] => {
  return data.getExperienceThemes;
};

export const useThemes = () => {
  const { experience } = usePageExperienceContext();
  const queryKey = createThemesQueryKey(String(experience?.id));

  return useQuery<GetExperienceThemesQuery, QueryError, ExperienceTheme[]>({
    queryKey,
    select: selector,
    queryFn: () => {
      return gqlClient.request(query, {
        input: { experienceId: String(experience?.id) },
      });
    },
    enabled: Boolean(experience),
  });
};
