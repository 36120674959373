import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useUpdateExperience } from '@/features/experiences/hooks/useUpdateExperience';
import { useAnalytics } from '@/hooks/useAnalytics';
import { BlockLayouts, BlockTypes } from '@/types/gql.generated';
import { Grid, GridItem } from '@/ui';
import { Control } from '../../components/Control';
import { Menu } from '../../components/Menu';
import { MenuHeading } from '../../components/MenuHeading';
import { MenuSection } from '../../components/MenuSection';
import { OptionButton } from '../../components/OptionButton';
import { useControlsContext } from '../../context/useControlsContext';
import { LayoutIcon } from './LayoutIcon';

const LayoutMenu = () => {
  const { trackEvent } = useAnalytics();
  const { experience } = usePageExperienceContext();
  const { setBlockLayout } = useUpdateExperience();

  const collectionBlock = experience?.blocks.find(
    (block) => block.type === BlockTypes.Collection
  );

  const handleClick = (layoutId: BlockLayouts) => () => {
    if (collectionBlock && experience?.layoutId) {
      trackEvent('experience:author change layout', {
        collectionLayout: layoutId,
        pageLayout: experience.layoutId,
      });
      setBlockLayout(collectionBlock.id, layoutId);
    }
  };

  return (
    <Menu>
      <MenuSection>
        <MenuHeading>List</MenuHeading>
        <Grid gap="3" templateColumns="repeat(3, 1fr)">
          <GridItem>
            <OptionButton
              icon={<LayoutIcon />}
              isActive={collectionBlock?.layoutId === BlockLayouts.ListByEvent}
              label="Single list"
              onClick={handleClick(BlockLayouts.ListByEvent)}
            />
          </GridItem>
          <GridItem>
            <OptionButton
              icon={<LayoutIcon label="Mon" />}
              isActive={collectionBlock?.layoutId === BlockLayouts.ListByDay}
              label="Grouped by day"
              onClick={handleClick(BlockLayouts.ListByDay)}
            />
          </GridItem>
          <GridItem>
            <OptionButton
              icon={<LayoutIcon label="Mon-Sun" />}
              isActive={collectionBlock?.layoutId === BlockLayouts.ListByWeek}
              label="Grouped by week"
              onClick={handleClick(BlockLayouts.ListByWeek)}
            />
          </GridItem>
          <GridItem>
            <OptionButton
              icon={<LayoutIcon label="Jan" />}
              isActive={collectionBlock?.layoutId === BlockLayouts.ListByMonth}
              label="Grouped by month"
              onClick={handleClick(BlockLayouts.ListByMonth)}
            />
          </GridItem>
          <GridItem>
            <OptionButton
              icon={<LayoutIcon label="2025" />}
              isActive={collectionBlock?.layoutId === BlockLayouts.ListByYear}
              label="Grouped by year"
              onClick={handleClick(BlockLayouts.ListByYear)}
            />
          </GridItem>
        </Grid>
      </MenuSection>
    </Menu>
  );
};

export const LayoutControl = () => {
  const { register } = useControlsContext();

  return (
    <Control
      icon="Layout"
      label="Layout"
      menu={<LayoutMenu />}
      {...register('layout')}
    />
  );
};
