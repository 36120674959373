import { useThemes } from '@/features/styles/hooks/useThemes';
import { ControlsContextProvider } from './context';
import { Controls } from './Controls';
import type { Props } from './types';

export const ControlsLoader = (props: Props) => {
  useThemes(); // Preload themes before user opens styles panel

  return (
    <ControlsContextProvider>
      <Controls {...props} />
    </ControlsContextProvider>
  );
};
