import { DateTime } from 'luxon';
import type {
  DecoratedExperienceEvent,
  DecoratedExperienceJob,
} from '../types';

export const getPastEvents = (
  job: DecoratedExperienceJob
): DecoratedExperienceEvent[] => {
  const today = DateTime.local().startOf('day');

  return job.events.filter((item) => {
    if (!item.lastEndDate) {
      return false;
    }
    return item.lastEndDate < today;
  });
};

export const hasPastEvent = (job: DecoratedExperienceJob): boolean => {
  return getPastEvents(job).length > 0;
};
