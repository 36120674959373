import { useMutation, type MutateOptions } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { PageExperienceFragment } from '@/features/experiences/fragments/PageExperience';
import type { GetPageExperienceQuery } from '@/features/experiences/hooks/useGetPageExperience.generated';
import { gqlClient, queryClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import { createExperienceQueryKey } from '@/utils/queryKeys';
import type {
  SetExperienceThemeMutation,
  SetExperienceThemeMutationVariables,
} from './useSetTheme.generated';

const query = gql`
  ${PageExperienceFragment}
  mutation SetExperienceTheme($input: SetExperienceThemeInput!) {
    setExperienceTheme(input: $input) {
      ...PageExperience
    }
  }
`;

export const useSetTheme = () => {
  const { experience } = usePageExperienceContext();

  const { mutate, ...rest } = useMutation<
    SetExperienceThemeMutation,
    QueryError,
    SetExperienceThemeMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request(query, variables);
    },
    onSuccess: (data) => {
      if (experience) {
        queryClient.setQueryData<GetPageExperienceQuery>(
          createExperienceQueryKey(experience.id),
          (prevValue) => {
            if (!prevValue) {
              return prevValue;
            }
            return {
              getExperience: {
                ...prevValue.getExperience,
                ...data.setExperienceTheme,
              },
            };
          }
        );

        queryClient.invalidateQueries({
          queryKey: createExperienceQueryKey(experience.id),
          exact: true,
        });
      }
    },
  });

  const setTheme = useCallback(
    (
      themeId: string | null,
      options?: MutateOptions<
        SetExperienceThemeMutation,
        QueryError,
        SetExperienceThemeMutationVariables
      >
    ) => {
      const input = {
        experienceId: String(experience?.id),
        themeId,
      };

      return mutate({ input }, options);
    },
    [mutate, experience?.id]
  );

  return {
    setTheme,
    ...rest,
  };
};
