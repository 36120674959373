import { useCallback, useMemo } from 'react';
import { useProfile } from '@/contexts/CurrentUserContext/hooks';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { BRAND_COLORS } from '@/features/styles/constants';
import { isWhiteColor, toHslString } from '@/features/styles/lib/colors';
import type { HSL } from '@/features/styles/types';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { ColorTypes } from '@/types/gql.generated';
import {
  Flex,
  forwardRef,
  Text,
  useRadioGroup,
  type UseRadioGroupProps,
} from '@/ui';
import { ColorPicker } from './ColorPicker/ColorPicker';
import { RadioColor } from './RadioColor';

type Props = Omit<UseRadioGroupProps, 'value'> & {
  value?: string;
  onSelectColor: (color: HSL) => void;
  onSelectCustomColor: (color: HSL) => void;
  onSelectCustomColorEnd: (color: HSL) => void;
};

export const ColorInput = forwardRef(
  (
    {
      value,
      onSelectColor,
      onSelectCustomColor,
      onSelectCustomColorEnd,
      isDisabled,
      ...props
    }: Props,
    ref
  ): JSX.Element => {
    const { experience } = usePageExperienceContext();
    const hasTheme = Boolean(experience?.theme);

    const profile = useProfile();
    const { isProTier } = profile;

    const { value: experiencesCustomColorEnabled } = useFeatureFlag(
      'experiencesCustomColorEnabled'
    );

    // Enable color picker if there is no theme and custom color is enabled
    const colorPickerEnabled =
      !hasTheme && (experiencesCustomColorEnabled || isProTier);

    const { getRootProps, getRadioProps } = useRadioGroup({
      ...props,
      value,
      isDisabled,
    });

    const hasInitialCustomColor = experience?.customHue !== null;

    const customHue = experience?.customHue ?? 0;
    const customSaturation = experience?.customSaturation ?? 0;
    const customLightness = experience?.customLightness ?? 0;
    const customAlpha = experience?.customAlpha ?? 1;

    const customColor: HSL = useMemo(
      () => ({
        hue: customHue,
        saturation: customSaturation,
        lightness: customLightness,
        alpha: customAlpha,
      }),
      [customHue, customSaturation, customLightness, customAlpha]
    );

    const initialColor = useMemo(
      () => (hasInitialCustomColor ? customColor : undefined),
      [hasInitialCustomColor, customColor]
    );

    const isSelected = useMemo(
      () => experience?.colorType === ColorTypes.Custom,
      [experience]
    );

    const updateCustomColor = useCallback(
      (color: HSL) => {
        onSelectCustomColor?.(color);
      },
      [onSelectCustomColor]
    );

    const persistCustomColor = useCallback(
      (color: HSL) => {
        onSelectCustomColorEnd?.(color);
      },
      [onSelectCustomColorEnd]
    );

    return (
      <Flex flexDir="column" gap="1.5">
        <Text fontWeight="bold">Color</Text>

        <Flex gap="1.5" justify="space-between" {...getRootProps()} ref={ref}>
          {colorPickerEnabled && (
            <Flex
              borderRightColor="gray.200"
              borderRightWidth="1px"
              h="100%"
              mr="1"
              pr="2.5"
              onClick={(event) => event.stopPropagation()}
            >
              <ColorPicker
                initialColor={initialColor}
                isSelected={isSelected}
                size={'22px'}
                onColorChange={updateCustomColor}
                onColorChangeEnd={persistCustomColor}
              />
            </Flex>
          )}

          {BRAND_COLORS.map((color) => {
            const hsl = toHslString(color);

            return (
              <RadioColor
                color={color}
                key={hsl}
                {...getRadioProps({ value: hsl })}
                hasBorder={isWhiteColor(color)}
                hasCheckIcon={!colorPickerEnabled}
                isDisabled={isDisabled}
                isChecked={
                  experience?.colorType === ColorTypes.Standard && value === hsl
                }
                onChange={() => onSelectColor?.(color)}
              />
            );
          })}
        </Flex>
      </Flex>
    );
  }
);
