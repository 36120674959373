import { DateTime } from 'luxon';
import {
  type ChangeEventHandler,
  type FocusEventHandler,
  type KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TimeInput as TimeInputField } from '@/pages/Schedule/components';
import { Button, Text, useUpdateEffect, usePrevious, Flex } from '@/ui';

type Props = {
  startDate: DateTime;
  endDate: DateTime;
  isAllDay: boolean;
  hasRecurrence: boolean;
  onAddTimeClick: () => void;
  onRemoveTimeClick: () => void;
  onStartTimeChange: (time: DateTime) => void;
  onEndTimeChange: (time: DateTime) => void;
  onClose: () => void;
};

const fromFormat = (date: string) => DateTime.fromFormat(date, 'HH:mm');
const toFormat = (date: DateTime) => date.toFormat('HH:mm');

export const TimeInput = ({
  startDate,
  endDate,
  isAllDay,
  onAddTimeClick,
  onRemoveTimeClick,
  onStartTimeChange,
  onEndTimeChange,
  onClose,
}: Props) => {
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  const [startTime, setStartTime] = useState(() => toFormat(startDate));
  useEffect(() => {
    setStartTime(toFormat(startDate));
  }, [startDate]);

  const [endTime, setEndTime] = useState(() => toFormat(endDate));
  useEffect(() => {
    setEndTime(toFormat(endDate));
  }, [endDate]);

  const handleStartTimeChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    setStartTime(event.currentTarget.value);

  const handleEndTimeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setEndTime(event.currentTarget.value);
  };

  const handleStartTimeBlur: FocusEventHandler<HTMLInputElement> = () => {
    const newStartTime = fromFormat(startTime);

    if (newStartTime.isValid) {
      onStartTimeChange(newStartTime);
    } else {
      setStartTime(toFormat(startDate));
    }
  };

  const handleEndTimeBlur: FocusEventHandler<HTMLInputElement> = () => {
    const newEndTime = fromFormat(endTime);

    if (newEndTime.isValid) {
      onEndTimeChange(newEndTime);
    } else {
      setEndTime(toFormat(endDate));
    }
  };

  const handleStartTimeKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      endInputRef.current?.focus();
    }
  };

  const handleEndTimeKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
      onClose();
    }
  };

  const prevIsOnDay = usePrevious(isAllDay);
  useUpdateEffect(() => {
    if (prevIsOnDay && !isAllDay) {
      // timeout is required for tests
      setTimeout(() => startInputRef.current?.select());
    }
  }, [isAllDay, prevIsOnDay]);

  if (isAllDay) {
    return (
      <Button
        alignSelf="flex-start"
        h="8"
        ml="3.5"
        size="sm"
        variant="link"
        onClick={onAddTimeClick}
      >
        Add time
      </Button>
    );
  }

  return (
    <Flex align="center" gap="2" justify="flex-start" wrap="wrap">
      <Flex align="center" flex="1" gap="2">
        <TimeInputField
          _hover={{ borderColor: 'gray.300' }}
          aria-label="Start time"
          autoFocus
          borderColor="transparent"
          flex="1"
          fontSize="md"
          ref={startInputRef}
          required
          size="sm"
          value={startTime}
          w="auto"
          onBlur={handleStartTimeBlur}
          onChange={handleStartTimeChange}
          onFocus={(event) => event.currentTarget.select()}
          onKeyDown={handleStartTimeKeyDown}
        />
        <Text variant="light">&ndash;</Text>
        <TimeInputField
          _hover={{ borderColor: 'gray.300' }}
          aria-label="End time"
          borderColor="transparent"
          flex="1"
          fontSize="md"
          max="24:00"
          min={startTime}
          ref={endInputRef}
          required
          size="sm"
          value={endTime}
          w="auto"
          onBlur={handleEndTimeBlur}
          onChange={handleEndTimeChange}
          onFocus={(event) => event.currentTarget.select()}
          onKeyDown={handleEndTimeKeyDown}
        />
      </Flex>

      <Button size="sm" variant="ghost" onClick={onRemoveTimeClick}>
        Remove
      </Button>
    </Flex>
  );
};
