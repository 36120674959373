import { useCallback } from 'react';
import { useProfile } from '@/contexts/CurrentUserContext/hooks';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useUpdateExperience } from '@/features/experiences/hooks/useUpdateExperience';
import { toHslString } from '@/features/styles/lib/colors';
import type { HSL } from '@/features/styles/types';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Feature } from '@/types/gql.generated';
import { Fade, Text } from '@/ui';
import { RequestPremiumContent } from '../../../RequestPremiumContent/RequestPremiumContent';
import { Control } from '../../components/Control';
import { Menu } from '../../components/Menu';
import { MenuSection } from '../../components/MenuSection';
import { OptionSlider } from '../../components/OptionSlider';
import { useControlsContext } from '../../context/useControlsContext';
import { ColorInput } from './ColorInput';
import { ThemeControl } from './ThemeControl';

const StylesMenu = () => {
  const { trackEvent } = useAnalytics();
  const { experience } = usePageExperienceContext();
  const profile = useProfile();
  const { isFreeTier } = profile;
  const { value: experiencesCustomColorEnabled } = useFeatureFlag(
    'experiencesCustomColorEnabled'
  );
  const { setBoldness, setSparkle, setHsl, setCustomHsl } =
    useUpdateExperience();

  const hasTheme = Boolean(experience?.theme);

  const handleSelectCustomColor = useCallback(
    (color: HSL) => {
      setCustomHsl({
        customHue: color.hue,
        customSaturation: color.saturation,
        customLightness: color.lightness,
        customAlpha: color.alpha,
      });
    },
    [setCustomHsl]
  );

  const handleSelectCustomColorEnd = useCallback(
    (color: HSL) => {
      trackEvent('experience:author change styles', {
        setting: 'color',
      });
      setCustomHsl(
        {
          customHue: color.hue,
          customSaturation: color.saturation,
          customLightness: color.lightness,
          customAlpha: color.alpha,
        },
        true
      );
    },
    [setCustomHsl, trackEvent]
  );

  const handleSelectColor = useCallback(
    (color: HSL) => {
      trackEvent('experience:author change styles', {
        setting: 'color',
      });
      setHsl(color);
    },
    [setHsl, trackEvent]
  );

  return (
    <Menu>
      <MenuSection gap="5">
        <ThemeControl />

        {hasTheme && experience?.theme?.description && (
          <Text color="customgray.mid" whiteSpace="pre-wrap">
            {experience.theme.description}
          </Text>
        )}

        {!hasTheme && (
          <>
            <OptionSlider
              label="Boldness"
              value={experience?.bold}
              onChange={(value) => setBoldness(value)}
              onChangeEnd={(value) => {
                trackEvent('experience:author change styles', {
                  setting: 'bold',
                });
                setBoldness(value, true);
              }}
            />

            <OptionSlider
              label="✨ Sparkle ✨"
              value={experience?.sparkle}
              onChange={(value) => setSparkle(value)}
              onChangeEnd={(value) => {
                trackEvent('experience:author change styles', {
                  setting: 'sparkle',
                });
                setSparkle(value, true);
              }}
            />
          </>
        )}

        <Fade in={!hasTheme}>
          <ColorInput
            isDisabled={hasTheme}
            value={toHslString(experience)}
            onSelectColor={handleSelectColor}
            onSelectCustomColor={handleSelectCustomColor}
            onSelectCustomColorEnd={handleSelectCustomColorEnd}
          />
        </Fade>
      </MenuSection>

      {!hasTheme && !experiencesCustomColorEnabled && isFreeTier && (
        <MenuSection borderTopRadius="none" borderTopWidth="1px" pb="5" pt="4">
          <RequestPremiumContent
            featureRequestType={Feature.CustomColors}
            pageId={experience?.id}
          />
        </MenuSection>
      )}
    </Menu>
  );
};

export const StylesControl = () => {
  const { register } = useControlsContext();

  return (
    <Control
      icon="Palette"
      label="Styles"
      {...register('styles')}
      menu={<StylesMenu />}
    />
  );
};
