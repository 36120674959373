import { Icon, IconButton } from '@/ui';
import { useControlsContext } from '../context/useControlsContext';

export const MenuCloseButton = () => {
  const { close } = useControlsContext();

  return (
    <IconButton
      aria-label="Close"
      display={{ md: 'none' }}
      icon={<Icon color="gray.900" icon="X" />}
      pos="absolute"
      right="3"
      size="xs"
      top="3"
      variant="ghost"
      zIndex="1"
      onClick={close}
    />
  );
};
