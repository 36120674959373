import { useParams } from 'react-router-dom';
import { useGetPageExperienceEvents } from '@/features/events/hooks/useGetPageExperienceEvents';
import { ChakraProvider } from '@/ui';
import { PageExperienceContextProvider } from '../../contexts/PageExperienceContext/PageExperienceContextProvider';
import { useChakraTheme } from '../../hooks/useChakraTheme';
import { useGetPageExperience } from '../../hooks/useGetPageExperience';
import { EditPageExperience } from './EditPageExperience';

export const EditPageExperienceLoader = () => {
  const { id: experienceId } = useParams<{ id: string }>();
  const experienceQuery = useGetPageExperience(experienceId);
  const eventsQuery = useGetPageExperienceEvents(experienceId);
  const { theme } = useChakraTheme();

  return (
    <ChakraProvider theme={theme}>
      <PageExperienceContextProvider
        eventsQuery={eventsQuery}
        experienceQuery={experienceQuery}
      >
        <EditPageExperience />
      </PageExperienceContextProvider>
    </ChakraProvider>
  );
};
