import { useRef } from 'react';
import { ConfirmDialogTitle } from '@/components/ConfirmDialog';
import { getPastEvents } from '@/features/events/lib/job';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Link,
  Text,
} from '@/ui';
import { useEditEventModal } from '../../EditEventModal';
import { useAddContentModalStore } from '../store';

export const PastWarningDialog = () => {
  const { job, isPastWarningOpen, closePastWarning } =
    useAddContentModalStore();
  const { open: openEventModal } = useEditEventModal();
  const buttonRef = useRef(null);

  const handleEdit = () => {
    closePastWarning();

    if (job) {
      const pastEvents = getPastEvents(job);

      if (pastEvents.length > 0) {
        openEventModal(pastEvents[0]);
      }
    }
  };

  return (
    <AlertDialog
      data-testid="confirm-dialog"
      isCentered
      isOpen={isPastWarningOpen && Boolean(job)}
      leastDestructiveRef={buttonRef}
      // `returnFocusOnClose`` is disabled because we can't guarantee that the element that
      // opened the modal will still be focusable when the modal closes. For example, this
      // modal can be opened from inside a `Menu` component on the agenda view and the menu
      // closes as soon as the menu item is clicked. Chakra is unable to return focus to the
      // menu item and the scroll position of the page is reset to the top.
      // https://github.com/chakra-ui/chakra-ui/issues/7734
      returnFocusOnClose={false}
      onClose={closePastWarning}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          borderRadius="20"
          minW={{ sm: '450px' }}
          mx={{ base: 4, sm: 0 }}
        >
          <AlertDialogBody px={{ base: 4, sm: '50px' }} py="10">
            <Text fontSize="56px">👋</Text>
            <ConfirmDialogTitle fontSize="4xl" fontWeight="bold">
              Heads up!
            </ConfirmDialogTitle>

            <Text mt="4">
              Your page is configured to show future events only, and the event
              you just added is in the past.
            </Text>
            <Text mt="2">
              If you want this event to appear on the page, you can change the
              selection of what to show from Only future events to All events.
            </Text>
            <Text mt="2">
              Or, you can keep the display of Only future events and{' '}
              <Link textDecor="underline" onClick={handleEdit}>
                edit the event
              </Link>{' '}
              to be in the future.
            </Text>

            <Flex gap="3" justify="flex-end" mt="8">
              <Button
                colorScheme="dark"
                ref={buttonRef}
                onClick={closePastWarning}
              >
                Got it
              </Button>
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
