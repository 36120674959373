import {
  autoUpdate,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react';
import { useState, type ReactNode } from 'react';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { ControlsContext } from './ControlsContext';
import type { ControlsContextValue } from './types';

type Props = {
  children: ReactNode;
};

export const ControlsContextProvider = ({ children }: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const [openControlId, setOpenControlId] = useState<string | null>(null);

  const { floatingStyles: menuStyles, refs: menuRefs } = useFloating({
    strategy: 'fixed',
    placement: isMobileBreakpoint ? 'top' : 'left-start',
    whileElementsMounted: autoUpdate,
    middleware: isMobileBreakpoint
      ? [
          offset({ mainAxis: 10, crossAxis: 0 }),
          shift({ padding: 12 }),
          size({
            apply({ rects, elements }) {
              Object.assign(elements.floating.style, {
                minWidth: `${rects.reference.width}px`,
              });
            },
          }),
        ]
      : [offset({ mainAxis: 16, crossAxis: -20 }), shift({ padding: 20 })],
  });

  const toggle = (controlId: string) => {
    setOpenControlId((prev) => (prev === controlId ? null : controlId));
  };

  const register = (controlId: string) => {
    return {
      controlId,
      isOpen: openControlId === controlId,
      onClick: () => toggle(controlId),
    };
  };

  const close = () => {
    setOpenControlId(null);
  };

  const value: ControlsContextValue = {
    menuStyles,
    menuRefs,
    openControlId,
    toggle,
    register,
    close,
  };

  return (
    <ControlsContext.Provider value={value}>
      {children}
    </ControlsContext.Provider>
  );
};
