import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Icon, IconButton, type Icons } from '@/ui';
import { useControlsContext } from '../context/useControlsContext';
import { ControlTooltip } from './ControlTooltip';

type Props = {
  isOpen?: boolean;
  controlId: string;
  icon: JSX.Element | keyof typeof Icons;
  label: string;
  menu?: JSX.Element;
  onClick: () => void;
};

export const Control = ({
  controlId,
  isOpen,
  icon,
  label,
  menu,
  onClick,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { menuStyles, menuRefs, openControlId } = useControlsContext();
  const isMobileBreakpoint = useIsMobileBreakpoint();

  // attach the floating menu to this control if it's active.
  // on mobile, the reference is the control bar itself.
  const ref = isMobileBreakpoint
    ? undefined
    : controlId === openControlId
      ? menuRefs.setReference
      : undefined;

  return (
    <>
      <ControlTooltip isDisabled={isOpen} label={label}>
        <IconButton
          _active={{ bg: 'purple.50' }}
          _focusVisible={{ boxShadow: 'outline' }}
          _hover={{ opacity: 1, bg: 'purple.50' }}
          aria-label={label}
          borderRadius="var(--control-border-radius)"
          boxSize="var(--control-box-size)"
          isActive={isOpen}
          opacity={isOpen ? 1 : 0.5}
          ref={ref}
          variant="ghost"
          icon={
            typeof icon === 'string' ? <Icon icon={icon} size="28px" /> : icon
          }
          onClick={() => {
            trackEvent('experience:author click edit-control', {
              control: controlId,
            });
            onClick();
          }}
        />
      </ControlTooltip>

      {isOpen && (
        <div ref={menuRefs.setFloating} style={menuStyles}>
          {menu}
        </div>
      )}
    </>
  );
};
