import { type ChangeEvent, memo } from 'react';
import { Input } from '@/ui';

type ColorInputFieldProps = {
  inputValue: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onInputKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  initialFocusRef: React.RefObject<HTMLInputElement>;
};

export const ColorInputField = memo(function ColorInputField({
  inputValue,
  onInputChange,
  onInputFocus,
  onInputBlur,
  onInputKeyDown,
  initialFocusRef,
}: ColorInputFieldProps) {
  return (
    <Input
      _focus={{ width: '100%' }}
      _hover={{ borderColor: 'gray.200' }}
      data-control
      focusBorderColor="brand.500"
      maxLength={7}
      placeholder="#FFFFFF"
      ref={initialFocusRef}
      size="md"
      value={inputValue}
      onBlur={onInputBlur}
      onChange={onInputChange}
      onFocus={onInputFocus}
      onKeyDown={onInputKeyDown}
    />
  );
});
