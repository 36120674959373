import { AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import { useKeyPressEvent } from 'react-use';
import { getDataset } from '@/features/common/dom.lib';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Divider, MotionBox, useMergeRefs, useOutsideClick } from '@/ui';
import { AddContentButton } from './components/AddContentButton';
import { Container } from './components/Container';
import { useControlsContext } from './context/useControlsContext';
import { EventsControl } from './controls/EventsControl';
import { InteractivityControl } from './controls/InteractivityControl';
import { LayoutControl } from './controls/LayoutControl';
import { StylesControl } from './controls/StylesControl';
import { TogglesControl } from './controls/TogglesControl';
import type { Props } from './types';

export const Controls = ({ onAddContentClick }: Props) => {
  const { close, menuRefs, openControlId } = useControlsContext();
  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    handler: (event) => {
      if (event.target && getDataset(event.target).control) {
        // Add `data-control` to elements outside the controls that a user might
        // click on that shouldn't dismiss the control. e.g. a menu list in a
        // portal
        return;
      }
      if (
        event.target &&
        (event.target as HTMLElement).closest('[data-control]')
      ) {
        // If the click occurred inside an element that a user might click on,
        // don't dismiss the control either.
        return;
      }

      close();
    },
    enabled: true,
    ref: containerRef,
  });
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { value: experiencesLayoutTabEnabled } = useFeatureFlag(
    'experiencesLayoutTabEnabled'
  );

  const mergedRefs = useMergeRefs(
    containerRef,
    isMobileBreakpoint ? menuRefs.setReference : undefined
  );

  useKeyPressEvent('Escape', close);

  const handleAddContentClick = () => {
    close();
    onAddContentClick();
  };

  return (
    <>
      {isMobileBreakpoint && (
        <AnimatePresence>
          {openControlId && (
            <MotionBox
              animate={{ opacity: 1 }}
              backdropFilter="blur(8px)"
              bg="rgba(0,0,0,0.4)"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              inset={0}
              pos="fixed"
              zIndex="1"
              onClick={close}
            />
          )}
        </AnimatePresence>
      )}

      <Container
        ref={mergedRefs}
        sx={{
          '--control-box-size': '48px',
          '--control-border-radius': 'var(--chakra-radii-xl)',
        }}
      >
        <AddContentButton onClick={handleAddContentClick} />
        <Divider
          borderColor="gray.500"
          h={{ base: 'var(--control-box-size)', md: 'auto' }}
          orientation={isMobileBreakpoint ? 'vertical' : 'horizontal'}
        />
        <StylesControl />
        {experiencesLayoutTabEnabled && <LayoutControl />}
        <TogglesControl />
        <EventsControl />
        <InteractivityControl />
      </Container>
    </>
  );
};
