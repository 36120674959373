import { useEditEventModal } from '../../hooks/useEditEventModal';
import { EditEventModal } from './EditEventModal';

export const EditEventModalLoader = () => {
  const { magicEvent } = useEditEventModal();

  if (!magicEvent) {
    return null;
  }

  return <EditEventModal magicEvent={magicEvent} />;
};
