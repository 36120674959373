import { memo } from 'react';

import { Box, IconButton, Icon } from '@/ui';

type EyedropperProps = {
  isSupported: () => boolean;
  onEyedropperClick: () => void;
};

export const Eyedropper = memo(function Eyedropper({
  isSupported,
  onEyedropperClick,
}: EyedropperProps) {
  if (!isSupported()) return null;

  return (
    <Box data-control>
      <IconButton
        _focus={{ color: 'brand.500' }}
        _hover={{ color: 'gray.300' }}
        alignItems="center"
        aria-label="Pick color from page"
        bg={'transparent'}
        border="1px"
        borderColor={'gray.400'}
        borderRadius="8px"
        color={'black'}
        data-control
        display="flex"
        icon={<Icon icon="Pipette" />}
        justifyContent="center"
        size="md"
        transition="color 0.3s ease"
        onClick={onEyedropperClick}
      />
    </Box>
  );
});
