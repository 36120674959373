import { CheckIcon } from '@chakra-ui/icons';
import { toHslString } from '@/features/styles/lib/colors';
import type { HSL } from '@/features/styles/types';
import { Flex, type FlexProps, type UseRadioProps, useRadio } from '@/ui';

type Props = UseRadioProps & {
  color: HSL;
  hasBorder?: boolean;
  hasCheckIcon?: boolean;
  containerProps?: FlexProps;
  onChange: (color: HSL) => void;
};

export const RadioColor = ({
  color,
  hasBorder,
  hasCheckIcon,
  containerProps,
  ...props
}: Props): JSX.Element => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const hasOutline = !hasCheckIcon && props.isChecked;

  return (
    <Flex
      align="center"
      as="label"
      cursor={props.isDisabled ? 'auto' : 'pointer'}
      {...containerProps}
    >
      <input {...getInputProps()} />

      <Flex
        {...getRadioProps()}
        align="center"
        bgColor={toHslString(color)}
        borderColor={props.isChecked ? 'white' : 'gray.400'}
        borderRadius="full"
        borderWidth={hasBorder || hasOutline ? '1px' : 0}
        boxSize="22px"
        justify="center"
        outline={hasOutline ? '1px solid' : 'none'}
        outlineColor={hasOutline ? 'purple.500' : 'transparent'}
        padding={props.isChecked ? '10px' : 0}
        transition="all 0.15s ease"
      >
        {hasCheckIcon && (
          <CheckIcon
            boxSize="12px"
            color={hasBorder ? 'black' : 'white'}
            display={props.isChecked ? 'block' : 'none'}
          />
        )}
      </Flex>
    </Flex>
  );
};
