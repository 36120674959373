import { DateTime } from 'luxon';
import type { ChangeEvent } from 'react';
import { type Options, RRule, type Weekday } from 'rrule';
import { TIME_ZONE_UTC } from '@/constants';
import type { DecoratedExperienceEvent } from '@/features/events/types';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import type { PageExperienceFragment } from '@/features/experiences/fragments/PageExperience.generated';
import type { RecurrenceOption } from '@/pages/Schedule/components';
import { getDefaultWeekday, parseRRule } from '@/pages/Schedule/utils';
import { applyTimeToDate, areDaysEqual, isMidnight } from '@/utils/dates';
import {
  getUntilDateForAllDayEntry,
  getUntilDateTimeForTimedEntry,
  getUntilDateStringForAllDayEntry,
} from './utils';

type UpdateMagicEventProps = Partial<
  Omit<DecoratedExperienceEvent, 'rrule'>
> & {
  rrule?: Partial<Options> | null;
};

const getEventTimeZone = (
  magicEvent: DecoratedExperienceEvent,
  experience?: PageExperienceFragment
): string => {
  if (magicEvent.isAllDay) {
    return experience?.timeZone ?? TIME_ZONE_UTC;
  }
  return magicEvent.timeZone;
};

export const useUpdateEventHandlers = (
  magicEvent: DecoratedExperienceEvent,
  onUpdate: (magicEvent: DecoratedExperienceEvent) => void
) => {
  const { experience } = usePageExperienceContext();
  const { rrule, startDate, endDate, isAllDay, timeZone } = magicEvent;

  const scheduleTimeZone = experience?.timeZone ?? timeZone;
  const local = () => DateTime.local({ zone: scheduleTimeZone });

  const { frequency, interval, byWeekday, untilDate } = parseRRule({
    rule: rrule,
    startDate,
    isOnDay: isAllDay,
    scheduleTimeZone,
    entryTimeZone: timeZone,
  });

  const updateEntry = (propsToUpdate: UpdateMagicEventProps) => {
    const nextIsAllDay = propsToUpdate.isAllDay ?? isAllDay;
    let nextRRule: string | null = rrule ?? null;

    if (typeof propsToUpdate.rrule !== 'undefined') {
      if (propsToUpdate.rrule === null) {
        nextRRule = null;
      } else {
        const newRRule = new RRule({
          ...RRule.parseString(rrule ?? ''),
          ...propsToUpdate.rrule,
        });

        const ruleStr = newRRule.toString();
        if (ruleStr) {
          nextRRule =
            nextIsAllDay && newRRule.options.until
              ? getUntilDateStringForAllDayEntry(ruleStr)
              : ruleStr;
        }
      }
    }

    const newMagicEvent: DecoratedExperienceEvent = {
      ...magicEvent,
      ...propsToUpdate,
      rrule: nextRRule,
    };

    onUpdate(newMagicEvent);
  };

  const onRecurrenceChange = (option: RecurrenceOption) => {
    const { freq, interval } = option.rule;

    // removing recurrence?
    if (freq === null) {
      updateEntry({ rrule: null });
      return;
    }

    let newEndDateTime = applyTimeToDate(startDate, endDate);
    if (isMidnight(newEndDateTime) || isAllDay) {
      newEndDateTime = newEndDateTime.plus({ days: 1 });
    }

    updateEntry({
      endDate: newEndDateTime,
      rrule: {
        freq,
        interval,
        bynweekday: [],
      },
    });
  };

  const onUntilDateChange = (untilDate: DateTime | null) => {
    if (untilDate) {
      const newUntilDate = isAllDay
        ? getUntilDateForAllDayEntry(untilDate)
        : untilDate;

      updateEntry({
        rrule: {
          until: newUntilDate.toJSDate(),
        },
      });
    } else {
      updateEntry({
        rrule: {
          until: null,
        },
      });
    }
  };

  const onAddTime = () => {
    const timeZone = getEventTimeZone(magicEvent, experience);
    const hour = local().hour;
    const newStartDate = startDate.set({ hour });
    const adjustedEndDate = endDate.minus({
      days: isAllDay ? 1 : 0,
    });
    const newEndDate = newStartDate.set({
      day: adjustedEndDate.day,
      month: adjustedEndDate.month,
      year: adjustedEndDate.year,
      hour: hour + 1,
    });

    const rrule = {
      until: untilDate
        ? getUntilDateTimeForTimedEntry(untilDate, timeZone).toJSDate()
        : null,
    };

    updateEntry({
      timeZone,
      startDate: newStartDate,
      endDate: newEndDate,
      isAllDay: false,
      rrule,
    });
  };

  const onRemoveTime = () => {
    const newStartDate = startDate.startOf('day');
    const newEndDate = endDate.startOf('day').plus({ days: 1 });

    const rrule = {
      until: untilDate
        ? getUntilDateForAllDayEntry(untilDate).toJSDate()
        : null,
    };

    updateEntry({
      timeZone: TIME_ZONE_UTC,
      startDate: newStartDate,
      endDate: newEndDate,
      isAllDay: true,
      rrule,
    });
  };

  const onStartDateChange = (selectedStartDate: DateTime) => {
    const calculateEndOrUntilDate = (date: DateTime): DateTime => {
      const granularity = isAllDay ? 'days' : 'minutes';
      const duration = date.diff(startDate, granularity)[granularity];
      return newStartDateTime.plus({ [granularity]: duration });
    };

    // Start date is whatever was selected + the existing start date time parts
    const newStartDateTime = applyTimeToDate(selectedStartDate, startDate);

    // exit early for non-recurring entries
    if (frequency === null) {
      updateEntry({
        startDate: newStartDateTime,
        endDate: calculateEndOrUntilDate(endDate),
      });
      return;
    }

    const newUntilDateTime = untilDate
      ? calculateEndOrUntilDate(untilDate).toJSDate()
      : null;

    // Set the end date properly. The until date takes over in the UI but the end
    // date is still part of the `recurrence` object and needs to be accurate
    const newEndDateTime = isAllDay
      ? newStartDateTime.startOf('day').plus({ days: 1 })
      : applyTimeToDate(newStartDateTime, endDate);

    updateEntry({
      startDate: newStartDateTime,
      endDate: newEndDateTime,
      rrule: {
        until: newUntilDateTime,
      },
    });
  };

  const onEndDateChange = (date: DateTime) => {
    updateEntry({
      endDate: applyTimeToDate(date, endDate),
    });
  };

  const onMonthChange = (weekday: Weekday | null) => {
    updateEntry({
      rrule: {
        byweekday: weekday === null ? null : [weekday],
      },
    });
  };

  const onWeekdayChange = (weekday: Weekday[]) => {
    updateEntry({
      rrule: {
        byweekday: weekday.length ? weekday : [getDefaultWeekday(startDate)],
      },
    });
  };

  const onStartTimeChange = (updatedStartDateTime: DateTime) => {
    const duration = endDate.diff(startDate, 'minutes').minutes;
    const newStartDate = applyTimeToDate(startDate, updatedStartDateTime);
    const newEndDate = newStartDate.plus({ minutes: duration });

    updateEntry({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const onEndTimeChange = (updatedEndDateTime: DateTime) => {
    let newEndDateTime = applyTimeToDate(endDate, updatedEndDateTime);

    // the end date is always relative to start date if recurrence is used
    if (frequency) {
      newEndDateTime = applyTimeToDate(startDate, updatedEndDateTime);
    }

    // Don't allow times earlier than the start time
    if (newEndDateTime < startDate) {
      newEndDateTime = startDate.plus({ hours: 1 });
    }

    // For midnight end times, ensure the date is the start of tomorrow
    // instead of midnight on the same date
    if (areDaysEqual(startDate, newEndDateTime) && isMidnight(newEndDateTime)) {
      newEndDateTime = newEndDateTime.plus({ days: 1 });
    }

    updateEntry({
      endDate: newEndDateTime,
    });
  };

  const onTitleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateEntry({ title: event.target.value });
  };

  const onEmojiChange = (emoji: string | null) => {
    updateEntry({ emoji });
  };

  const onLocationChange = (name: string, googlePlaceId: string | null) => {
    updateEntry({
      locationWithPlace: {
        name,
        googlePlaceId,
      },
    });
  };

  const onDescriptionChange = (description: string) => {
    updateEntry({ description });
  };

  return {
    // parsed rrule props
    frequency,
    interval,
    byWeekday,
    untilDate,
    // date + time handlers
    onStartDateChange,
    onEndDateChange,
    onStartTimeChange,
    onEndTimeChange,
    onAddTime,
    onRemoveTime,
    onUntilDateChange,
    onRecurrenceChange,
    onMonthChange,
    onWeekdayChange,
    // other metadata handlers
    onTitleChange,
    onEmojiChange,
    onLocationChange,
    onDescriptionChange,
  };
};
