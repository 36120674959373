import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useSetTheme } from '@/features/styles/hooks/useSetTheme';
import { useThemes } from '@/features/styles/hooks/useThemes';
import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@/ui';

export const ThemeControl = () => {
  const { experience } = usePageExperienceContext();
  const { data: themes = [] } = useThemes();
  const { setTheme } = useSetTheme();
  const hasThemes = themes.length > 0;

  if (!experience || (!hasThemes && !experience?.theme)) {
    return null;
  }

  // Don't allow author to change the theme if they don't have access to the
  // current theme and therefore won't be able to change it back
  const authorHasCurrentTheme = Boolean(
    !experience.theme || themes.some((item) => item.id === experience.theme?.id)
  );

  return (
    <Menu isLazy matchWidth>
      <MenuButton
        as={Button}
        colorScheme="dark"
        isDisabled={!authorHasCurrentTheme}
        variant="outline"
        w="100%"
      >
        <Flex align="center" as="span" gap="2">
          <Text as="span" mr="auto">
            {experience.theme ? experience.theme.name : 'Agenda Hero Styles'}
          </Text>
          <Icon icon="ChevronDown" />
        </Flex>
      </MenuButton>
      <Portal>
        <MenuList data-control>
          {themes.map((theme) => {
            const isActive = theme.id === experience.theme?.id;

            return (
              <MenuItem
                data-control
                icon={isActive ? <Icon icon="Checkmark" /> : undefined}
                key={theme.id}
                pl={isActive ? '3' : '10'}
                onClick={() => setTheme(isActive ? null : theme.id)}
              >
                {theme.name}
              </MenuItem>
            );
          })}

          {experience.theme && (
            <>
              <MenuDivider />
              <MenuItem
                data-control="styles"
                pl="10"
                onClick={() => setTheme(null)}
              >
                Agenda Hero Styles
              </MenuItem>
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};
