import { ClassNames } from '@emotion/react';
import { toHex } from 'color2k';
import { type ChangeEvent, useCallback, useRef, useState } from 'react';
import { type HslaColor, HslaColorPicker } from 'react-colorful';
import useEyeDropper from 'use-eye-dropper';
import { DEFAULT_PICKER_COLOR } from '@/features/styles/constants';
import {
  isHexColor,
  toHslaString,
  hexToHsl,
  toHslString,
} from '@/features/styles/lib/colors';
import type { HSL } from '@/features/styles/types';

import { Sentry } from '@/lib';
import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  type PopoverProps,
  Portal,
  useDisclosure,
} from '@/ui';

import './styles.scss';
import { ColorInputField } from './ColorInputField';
import { ColorPreview } from './ColorPreview';
import { Eyedropper } from './Eyedropper';

type ColorPickerProps = {
  initialColor?: HSL;
  isSelected?: boolean;
  onColorChange?: (color: HSL) => void;
  onColorChangeEnd?: (color: HSL) => void;
  size?: string;
  placement?: PopoverProps['placement'];
  zIndex?: string;
};

export const ColorPicker = ({
  initialColor,
  isSelected,
  onColorChange,
  onColorChangeEnd,
  size = '22px',
  placement = 'bottom-start',
  zIndex = 'calc(var(--chakra-zIndices-menu) + 1)',
}: ColorPickerProps) => {
  const [currentColor, setCurrentColor] = useState<HSL>(
    initialColor ?? DEFAULT_PICKER_COLOR
  );
  const [inputValue, setInputValue] = useState(
    toHex(toHslString(initialColor ?? DEFAULT_PICKER_COLOR)).toUpperCase()
  );
  const [isEditing, setIsEditing] = useState(false);

  const popoverRef = useRef<HTMLElement>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { open, isSupported } = useEyeDropper();

  const selected = isSelected || isOpen;

  const handleColorChange = useCallback(
    (color: HslaColor) => {
      const parsedColor = {
        hue: color.h,
        saturation: color.s,
        lightness: color.l,
        alpha: color.a,
      };

      setCurrentColor(parsedColor);

      if (!isEditing) {
        setInputValue(
          toHex(
            toHslaString(
              parsedColor.hue,
              parsedColor.saturation,
              parsedColor.lightness,
              parsedColor.alpha
            )
          ).toUpperCase()
        );
      }

      onColorChange?.(parsedColor);
    },
    [isEditing, onColorChange]
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value;

    // Ensure the value starts with a '#' symbol
    if (!value.startsWith('#')) {
      value = `#${value}`;
    }

    setInputValue(value);

    if (isHexColor(value)) {
      const parsedColor = hexToHsl(value);
      setCurrentColor(parsedColor);
      onColorChangeEnd?.(parsedColor);
    }
  };

  const handleOpen = (): void => {
    // if this is the first time
    setCurrentColor(currentColor);
    setIsEditing(false);
    setInputValue(
      toHex(
        toHslaString(
          currentColor.hue,
          currentColor.saturation,
          currentColor.lightness,
          currentColor.alpha
        )
      ).toUpperCase()
    );

    // set the initial page color when the popover is opened
    onColorChangeEnd?.(currentColor);
    onOpen();
  };

  const handleClose = (): void => {
    setIsEditing(false);
    onClose();
  };

  const handleEyedropperClick = useCallback(() => {
    const openPicker = async () => {
      try {
        const eyedropperColor = await open();
        const parsedColor = hexToHsl(eyedropperColor.sRGBHex);

        // update color picker color + input value
        setCurrentColor(parsedColor);
        if (!isEditing) {
          setInputValue(
            toHex(
              toHslaString(
                parsedColor.hue,
                parsedColor.saturation,
                parsedColor.lightness,
                parsedColor.alpha
              )
            ).toUpperCase()
          );
        }

        // send color change to the server
        onColorChangeEnd?.(parsedColor);
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
      }
    };
    openPicker();
  }, [open, isEditing, onColorChangeEnd]);

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      placement={placement}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <PopoverTrigger>
        <Box boxSize={size} data-control ref={triggerRef}>
          <ColorPreview
            color={initialColor ? currentColor : undefined}
            isSelected={selected}
            size={size}
            onOpenPicker={handleOpen}
          />
        </Box>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          data-control
          pb={2}
          pt={2}
          ref={popoverRef}
          width="auto"
          zIndex={zIndex}
          onClick={(e) => e.stopPropagation()} // Prevent event propagation
        >
          <PopoverArrow data-control />

          <PopoverBody data-control>
            <Flex align="center" data-control direction="column" gap={4}>
              <ClassNames>
                {({ cx }) => (
                  <Box
                    className={cx('styles-color-picker')}
                    data-control
                    display="flex"
                    justifyContent="center"
                    width="100%"
                  >
                    <HslaColorPicker
                      data-control
                      color={{
                        h: currentColor.hue,
                        s: currentColor.saturation,
                        l: currentColor.lightness,
                        a: currentColor.alpha ?? 1,
                      }}
                      onChange={handleColorChange}
                      onMouseUp={(e) => {
                        onColorChangeEnd?.(currentColor);
                        e.preventDefault();
                      }}
                      onTouchEnd={(e) => {
                        onColorChangeEnd?.(currentColor);
                        e.preventDefault();
                      }}
                    />
                  </Box>
                )}
              </ClassNames>

              <Flex align="center" data-control gap={2} width="100%">
                <ColorInputField
                  initialFocusRef={initialFocusRef}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  onInputFocus={() => setIsEditing(true)}
                  onInputBlur={() => {
                    setIsEditing(false);
                    // reset invalid color input
                    if (!isHexColor(inputValue)) {
                      setInputValue(
                        toHex(
                          toHslaString(
                            currentColor.hue,
                            currentColor.saturation,
                            currentColor.lightness,
                            currentColor.alpha ?? 1
                          )
                        ).toUpperCase()
                      );
                    }
                  }}
                  onInputKeyDown={(e) => {
                    if (e.key === 'Enter' && isHexColor(inputValue)) {
                      onColorChangeEnd?.(currentColor);
                      e.preventDefault(); // Prevent default behavior
                    }
                  }}
                />

                <Eyedropper
                  isSupported={isSupported}
                  onEyedropperClick={handleEyedropperClick}
                />
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
