import { memo } from 'react';
import { isWhiteColor, toHslString } from '@/features/styles/lib/colors';
import type { HSL } from '@/features/styles/types';

import { Box, Icon } from '@/ui';

type ColorPreviewProps = {
  color?: HSL;
  isSelected?: boolean;
  size?: string;
  onOpenPicker: () => void;
};

export const ColorPreview = memo(function ColorPreview({
  color,
  isSelected,
  size = '22px',
  onOpenPicker,
}: ColorPreviewProps) {
  if (!color) {
    return (
      <Box
        _active={{ transform: 'scale(0.95)' }}
        _hover={{ transform: 'scale(1.05)' }}
        aria-label="Open color picker"
        as="button"
        background="conic-gradient(from 180deg at 50% 50%, #F3413B 42.23712086677551deg, #F3413B 44.62756723165512deg, #FFDE7B 110.29947280883789deg, #6BC478 147.77509331703186deg, #45AAA9 182.48767375946045deg, #4386F2 225.5809235572815deg, #768CF2 273.98975372314453deg, #E653E6 329.5130467414856deg)"
        borderRadius="full"
        boxShadow="md"
        cursor="pointer"
        height={size}
        position="relative"
        transition="transform 0.2s ease"
        width={size}
        onClick={onOpenPicker}
      />
    );
  }

  return (
    <Box
      aria-label="Open color picker"
      as="button"
      bgColor={toHslString(color)}
      borderColor={isSelected ? 'white' : 'gray.400'}
      borderRadius="full"
      borderWidth={isWhiteColor(color) || isSelected ? '1px' : 0}
      cursor="pointer"
      height={size}
      outline={isSelected ? '1px solid' : 'none'}
      outlineColor={isSelected ? 'purple.500' : 'transparent'}
      position="relative"
      width={size}
      onClick={onOpenPicker}
    >
      <Icon
        _hover={{ color: isWhiteColor(color) ? 'black' : 'white' }}
        color={'transparent'}
        icon="Settings2"
        size="12px"
        transition={'color 0.3s ease'}
      />
    </Box>
  );
});
