import { useEffect } from 'react';
import { AppLoader } from '@/components/AppLoader';
import {
  AddContentModal,
  useAddContentModalStore,
} from '@/features/events/components/AddContentModal';
import { EditEventModal } from '@/features/events/components/EditEventModal';
import { useStyles } from '@/features/styles/hooks/useStyles';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIntercom } from '@/hooks/useIntercom';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useMetaThemeColor } from '@/hooks/useMetaThemeColor';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Flex } from '@/ui';
import { Controls } from '../../components/Controls';
import { PageExperience } from '../../components/PageExperience';
import { PageHeader } from '../../components/PageHeader';
import { usePageExperienceContext } from '../../contexts/PageExperienceContext';

export const EditPageExperience = () => {
  const { trackEvent } = useAnalytics();
  const { open } = useAddContentModalStore();
  const { experience, experienceQuery } = usePageExperienceContext();
  const { id: experienceId } = experience || {};
  const { visualBackgroundColor } = useStyles();
  useIntercom({ hideOnMobile: true });

  useLauncherAction('experience:onboarding', () => {
    open({ launchState: 'onboarding' });
  });

  usePageTitle(experience?.title);

  usePageTitle(experience?.title);
  useMetaThemeColor(visualBackgroundColor);

  useEffect(() => {
    if (experienceId) {
      trackEvent('experience:view', {
        experienceId,
        mode: 'edit',
      });
    }
  }, [trackEvent, experienceId]);

  return (
    <Flex
      flex="1"
      flexDir="column"
      sx={{
        opacity: 1,
        transition: 'opacity 0.5s ease',
        '@starting-style': {
          opacity: 0,
        },
      }}
    >
      <AddContentModal />
      <PageHeader />
      <Controls onAddContentClick={open} />
      <EditEventModal />

      {experienceQuery.isLoading ? <AppLoader /> : <PageExperience />}
    </Flex>
  );
};
