import { useConditionallyMount } from '@/hooks/useConditionallyMount';
import { AddContentModal } from './AddContentModal';
import { PastWarningDialog } from './components/PastWarningDialog';
import { useAddContentModalStore } from './store';

export const AddContentModalLoader = () => {
  const { isOpen } = useAddContentModalStore();
  const { mounted, setMounted } = useConditionallyMount(isOpen);

  return (
    <>
      {mounted && <AddContentModal onCloseComplete={() => setMounted(false)} />}
      <PastWarningDialog />
    </>
  );
};
