import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { ExperienceEventFragment } from '@/features/events/fragments/ExperienceEventFragment';
import { gqlClient } from '@/lib';
import type { GetExperienceInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { createExperienceEventsQueryKey } from '@/utils/queryKeys';
import type { GetPageExperienceEventsQuery } from './useGetPageExperienceEvents.generated';

type RawEvent = GetPageExperienceEventsQuery['getExperience']['events'][number];

const query = gql`
  ${ExperienceEventFragment}
  query GetPageExperienceEvents(
    $input: GetExperienceInput!
    $filters: ListExperienceEventInput!
  ) {
    getExperience(input: $input) {
      events(filters: $filters) {
        ...ExperienceEvent
      }
    }
  }
`;

const selector = (data: GetPageExperienceEventsQuery): RawEvent[] => {
  return data.getExperience.events;
};

export const useGetPageExperienceEvents = (
  experienceId: string | undefined
) => {
  const queryKey = createExperienceEventsQueryKey(experienceId);

  return useQuery<GetPageExperienceEventsQuery, QueryError, RawEvent[]>({
    enabled: Boolean(experienceId),
    queryKey,
    select: selector,
    queryFn: () => {
      const input: GetExperienceInput = {
        experienceId: experienceId!, // Covered by enabled above
      };
      return gqlClient.request(query, { input, filters: {} });
    },
  });
};
