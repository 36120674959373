import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiDisabledAlert } from '@/components/AiDisabledAlert';
import { Collapse } from '@/components/Collapse';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { Confetti } from '@/features/experiences/components/Confetti';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { EventListDisplay, MagicJobType } from '@/types/gql.generated';
import {
  Alert,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  type ModalProps,
  useDisclosure,
  useToast,
} from '@/ui';
import { hasPastEvent } from '../../lib/job';
import { ContentContainer } from './components/ContentContainer';
import { EmptyState } from './components/EmptyState';
import { LoadingState } from './components/LoadingState';
import { ModalBody } from './components/ModalBody';
import { ModalContent } from './components/ModalContent';
import { ModalHeader } from './components/ModalHeader';
import { PromptState } from './components/PromptState';
import { ResultsState } from './components/ResultsState';
import { useCreateMagicJob } from './hooks/useCreateMagicJob';
import { useImportMagicJob } from './hooks/useImportMagicJob';
import { useMeasurePromptHeight } from './hooks/useMeasurePromptHeight';
import { usePollMagicJobStatus } from './hooks/usePollMagicJobStatus';
import { usePrompt } from './hooks/usePrompt';
import { useResetKey } from './hooks/useResetKey';
import { useAddContentModalStore } from './store';

type Props = Pick<ModalProps, 'onCloseComplete'>;

export const AddContentModal = ({ onCloseComplete }: Props) => {
  const [instructions, setInstructions] = useState('');
  const [jobType, setJobType] = useState(MagicJobType.Text);
  const { t } = useTranslation('addContent');
  const {
    isOpen,
    close,
    reset: resetStore,
    openPastWarning,
  } = useAddContentModalStore();
  const { prompt, setPrompt, resetPrompt } = usePrompt();
  const { resetKey, generateResetKey } = useResetKey();
  const toast = useToast();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { experience } = usePageExperienceContext();
  const { value: disableAllAiFeatures } = useFeatureFlag(
    'disableAllAiFeatures'
  );

  const {
    isOpen: isConfirmOpen,
    onOpen: openConfirm,
    onClose: closeConfirm,
  } = useDisclosure();

  const {
    createMagicJob,
    error: magicJobError,
    reset: resetMagicJob,
  } = useCreateMagicJob(jobType);

  const {
    poll,
    job,
    error: pollJobError,
    isSuccess: pollJobSuccess,
    reset: resetPollJob,
  } = usePollMagicJobStatus();

  const {
    importMagicJob,
    isSuccess: isImportSuccess,
    isPending: isImportPending,
    reset: resetImport,
  } = useImportMagicJob();

  const { promptRef, promptHeight, setMeasurePromptHeightEnabled } =
    useMeasurePromptHeight();

  const error = magicJobError || pollJobError;

  const [isPending, setPending] = useState(false);
  useEffect(() => {
    if (pollJobError || pollJobSuccess) {
      setPending(false);
    }
  }, [pollJobError, pollJobSuccess]);

  const handleSubmit = async () => {
    setPending(true);
    setMeasurePromptHeightEnabled(false);

    createMagicJob(prompt, {
      onSuccess: (data) => {
        poll(data.createMagicJob.id);
      },
    });
  };

  const handleImport = () => {
    if (job && experience) {
      importMagicJob(experience.id, job.id, {
        onSuccess: () => {
          close();
          toast.toast({
            position: 'bottom-right',
            render: () => (
              <Alert variant="notify">
                <Confetti />
                🎉 Added!
              </Alert>
            ),
          });

          if (
            hasPastEvent(job) &&
            experience.eventListDisplay === EventListDisplay.Upcoming
          ) {
            openPastWarning(job);
          }
        },
        onError: () => {
          toast.notify('Error adding events to your page experience');
        },
      });
    }
  };

  const handleClose = () => {
    if (isPending || (!isImportSuccess && job && job?.events.length > 0)) {
      openConfirm();
    } else {
      close();
    }
  };

  const handleCloseConfirm = () => {
    closeConfirm();
    close();
  };

  const handleDropText = (text: string) => {
    setPrompt({ html: text });
    setJobType(MagicJobType.Text);
  };

  const handleReset = () => {
    resetMagicJob();
    resetPollJob();
    resetPrompt();
    resetImport();
    setPending(false);
    setMeasurePromptHeightEnabled(true);
    setInstructions('');
    resetStore();
    generateResetKey();
  };

  return (
    <>
      <ConfirmDialog
        cancelButtonText={t('close_confirm.cancel')}
        confirmButtonText={t('close_confirm.confirm')}
        isOpen={isConfirmOpen}
        title={t('close_confirm.title')}
        onCancel={closeConfirm}
        onConfirm={handleCloseConfirm}
      />
      <Modal
        closeOnEsc={!isPending}
        closeOnOverlayClick={!isPending}
        isCentered
        isOpen={isOpen}
        returnFocusOnClose={false}
        onClose={handleClose}
        onCloseComplete={onCloseComplete}
      >
        <ModalOverlay />

        <ModalContent key={resetKey} pollJobSuccess={pollJobSuccess}>
          <ModalHeader pollJobSuccess={pollJobSuccess} />
          <ModalCloseButton right={{ base: 6, md: 8 }} top="22px" />

          <ModalBody pollJobSuccess={pollJobSuccess}>
            {disableAllAiFeatures ? (
              <AiDisabledAlert />
            ) : (
              <ContentContainer pollJobSuccess={pollJobSuccess}>
                <Collapse flexShrink="0" in={!!error} w="100%">
                  {error && <QuickCreateErrorMessage error={error} w="100%" />}
                </Collapse>

                <AnimatePresence
                  // let the loading state animate out first before bringing
                  // in the results. prevents the loading state from fading
                  // out while the expanding animation is in progress, which
                  // doesn't look great'
                  mode={
                    pollJobSuccess && !isMobileBreakpoint ? 'wait' : 'popLayout'
                  }
                >
                  {isPending ? (
                    <LoadingState key="loading" minH={`${promptHeight}px`} />
                  ) : job && !job.events.length ? (
                    <EmptyState
                      key="empty"
                      onClose={handleClose}
                      onReset={handleReset}
                    />
                  ) : job && job.events.length > 0 ? (
                    <ResultsState
                      isImportPending={isImportPending}
                      job={job}
                      key="results"
                      onImport={handleImport}
                    />
                  ) : (
                    <PromptState
                      instructions={instructions}
                      jobType={jobType}
                      key="prompt"
                      prompt={prompt}
                      promptRef={promptRef}
                      onCancel={close}
                      onChangeJobType={setJobType}
                      onClose={handleClose}
                      onDropText={handleDropText}
                      onInstructionsChange={setInstructions}
                      onPromptChange={setPrompt}
                      onSubmit={handleSubmit}
                    />
                  )}
                </AnimatePresence>
              </ContentContainer>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
