import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useUpdateExperience } from '@/features/experiences/hooks/useUpdateExperience';
import { useAnalytics } from '@/hooks/useAnalytics';
import { EventListDisplay, TimeZoneDisplay } from '@/types/gql.generated';
import { Control } from '../../components/Control';
import { Menu } from '../../components/Menu';
import { MenuHeading } from '../../components/MenuHeading';
import { MenuSection } from '../../components/MenuSection';
import { OptionRadioGroup } from '../../components/OptionRadioGroup';
import { useControlsContext } from '../../context/useControlsContext';
import { TimeZoneSelect } from './TimeZoneSelect';

const EventsMenu = () => {
  const { trackEvent } = useAnalytics();
  const { experience } = usePageExperienceContext();
  const { setTimezone, setTimezoneDisplay, setEventListDisplay } =
    useUpdateExperience();

  if (!experience) {
    return null;
  }

  return (
    <Menu>
      <MenuSection>
        <MenuHeading>Time zone</MenuHeading>
        <TimeZoneSelect
          value={experience.timeZone}
          onChange={(event) => {
            trackEvent('experience:author change event-settings', {
              setting: 'timezone',
            });
            setTimezone(event.currentTarget.value);
          }}
        />

        <MenuHeading mt="8">Display events</MenuHeading>
        <OptionRadioGroup
          value={experience.timeZoneDisplay}
          options={[
            {
              value: TimeZoneDisplay.Experience,
              label: 'In the time zone above',
            },
            {
              value: TimeZoneDisplay.Visitor,
              label: 'In the time zone of the visitor',
            },
          ]}
          onChange={(value) => {
            trackEvent('experience:author change event-settings', {
              setting: 'timezone-display',
            });
            setTimezoneDisplay(value);
          }}
        />

        <MenuHeading mt="8">What to show</MenuHeading>
        <OptionRadioGroup
          value={experience.eventListDisplay}
          options={[
            { value: EventListDisplay.Past, label: 'All events' },
            { value: EventListDisplay.Upcoming, label: 'Only future events' },
          ]}
          onChange={(value) => {
            trackEvent('experience:author change event-settings', {
              setting: 'history-toggle',
            });
            setEventListDisplay(value);
          }}
        />
      </MenuSection>
    </Menu>
  );
};

export const EventsControl = () => {
  const { register } = useControlsContext();

  return (
    <Control
      icon="Clock"
      label="Events"
      menu={<EventsMenu />}
      {...register('events')}
    />
  );
};
