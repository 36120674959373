import { useState } from 'react';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@/ui';
import { useEditEventModal } from '../../hooks/useEditEventModal';
import { useUpdateEvent } from '../../hooks/useUpdateEvent';
import type { DecoratedExperienceEvent } from '../../types';
import { DateInput } from './components/DateInput/DateInput';
import { DescriptionInput } from './components/DescriptionInput';
import { EmojiInput } from './components/EmojiInput';
import { LocationInput } from './components/LocationInput';
import { TitleInput } from './components/TitleInput';
import { useUpdateEventHandlers } from './hooks/useUpdateEventHandlers';

type Props = {
  magicEvent: DecoratedExperienceEvent;
};

export const EditEventModal = ({ magicEvent }: Props) => {
  const { close: closeModal } = useEditEventModal();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const [optimisticMagicEvent, setOptimisticMagicEvent] = useState(magicEvent);
  const { updateEvent, isPending: isUpdatingEvent } = useUpdateEvent();
  const toast = useToast();

  const {
    isOpen: isConfirmOpen,
    onOpen: openConfirm,
    onClose: closeConfirm,
  } = useDisclosure();

  // TODO: compare `optimisticMagicEvent` with `magicEvent` instead
  const [isDirty, setDirty] = useState(false);

  const {
    byWeekday,
    frequency,
    interval,
    untilDate,
    onEmojiChange,
    onTitleChange,
    onDescriptionChange,
    onLocationChange,
    onAddTime,
    onRemoveTime,
    onStartDateChange,
    onEndDateChange,
    onStartTimeChange,
    onEndTimeChange,
    onMonthChange,
    onRecurrenceChange,
    onWeekdayChange,
    onUntilDateChange,
  } = useUpdateEventHandlers(optimisticMagicEvent, (updatedMagicEvent) => {
    setOptimisticMagicEvent(updatedMagicEvent);
    setDirty(true);
  });

  const handleSave = () => {
    updateEvent(optimisticMagicEvent, {
      onSuccess: () => closeModal(),
      onError: () => toast.error('Error saving event'),
    });
  };

  const handleCancel = () => {
    if (isDirty) {
      openConfirm();
    } else {
      closeModal();
    }
  };

  return (
    <>
      <ConfirmDialog
        cancelButtonText="Go back"
        confirmButtonText="Yes, cancel"
        isOpen={isConfirmOpen}
        message="Your unsaved changes will be lost"
        title="Are you sure?"
        onCancel={closeConfirm}
        onConfirm={closeModal}
      />

      <Modal
        closeOnEsc={!isUpdatingEvent}
        closeOnOverlayClick={!isUpdatingEvent}
        isCentered
        isOpen
        onClose={handleCancel}
      >
        <ModalOverlay backdropFilter="blur(8px)" bg="blackAlpha.600" />

        <ModalContent
          borderRadius={{ md: 'lg' }}
          h={{ md: 'auto' }}
          maxH={{ base: 'var(--app-height)', md: 'auto' }}
          minH={{ base: 'var(--app-height)', md: 'auto' }}
          minW={{ base: '100vw', md: '750px' }}
          sx={{ '--edge-padding': 'var(--chakra-space-8)' }}
        >
          <Flex
            gap="1"
            justify="flex-end"
            px="var(--edge-padding)"
            py="calc(var(--edge-padding) / 2)"
          >
            <Button size="sm" variant="ghost" onClick={handleCancel}>
              Cancel
            </Button>
            <Button isLoading={isUpdatingEvent} size="sm" onClick={handleSave}>
              Save
            </Button>
          </Flex>

          <ModalBody
            maxH={{ md: '800px' }}
            overflowY="auto"
            pb="var(--edge-padding)"
            pt="0"
            px="var(--edge-padding)"
          >
            <Flex flexDir="column" gap="4">
              <EmojiInput
                value={optimisticMagicEvent.emoji}
                onSelect={onEmojiChange}
              />
              <TitleInput
                autoFocus={!isMobileBreakpoint}
                placeholder="Enter title"
                value={optimisticMagicEvent.title}
                onChange={onTitleChange}
                onEnterPress={handleSave}
              />
              <Flex flexDir="column" gap="2.5">
                <DateInput
                  byWeekday={byWeekday}
                  frequency={frequency}
                  interval={interval}
                  magicEvent={optimisticMagicEvent}
                  untilDate={untilDate}
                  onAddTimeClick={onAddTime}
                  onEndDateChange={onEndDateChange}
                  onEndTimeChange={onEndTimeChange}
                  onMonthChange={onMonthChange}
                  onRecurrenceChange={onRecurrenceChange}
                  onRemoveTimeClick={onRemoveTime}
                  onStartDateChange={onStartDateChange}
                  onStartTimeChange={onStartTimeChange}
                  onUntilDateChange={onUntilDateChange}
                  onWeekdayChange={onWeekdayChange}
                />
                <LocationInput
                  value={optimisticMagicEvent.locationWithPlace?.name ?? ''}
                  onChange={onLocationChange}
                />
              </Flex>
              <DescriptionInput
                value={optimisticMagicEvent.description ?? ''}
                onChange={onDescriptionChange}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
